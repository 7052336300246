<template>
    <client-page>
        <template #subHead>
            <sub-visual-primary tit="브랜드" bg="/images/shop/brands/visual.jpg">
                <template #path>
                    <visual-breadcrumbs />
                </template>
            </sub-visual-primary>
        </template>
        <page-section containerSize="container--lg" class="page-section--first page-section--last">
            <brand-list-nav />

            <brand-search-bar/>

            <v-sheet v-bind="{ loading }" class="mt-40px mt-lg-60px">
                <v-row>
                    <v-col cols="6" md="4" lg="3" v-for="item in items" :key="item._id" class="py-10px py-md-20px">
                        <v-card :to="`/shop/brands/${item?.code}?category=${categories[0]?.code}`" tile>
                            <div class="square-img border" :style="`background-image:url(&quot;${item?.thumb?.url}&quot;);`" />
                            <div class="pt-12px pt-md-14px text-center">
                                <!-- <div class="txt txt--sm txt--dark text-truncate mb-4px mb-md-8px">{{ item.desc }}</div> -->
                                <div class="txt txt--dark">{{ item.name }}</div>
                                <div class="txt txt--sm">{{ item.engName }}</div>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
                <v-fade-transition leave-absolute>
                    <v-sheet v-show="loading" height="50vh">
                        <v-layout fill-height column justify-center align-center>
                            <v-card-title> 데이터를 불러오고 있습니다</v-card-title>
                        </v-layout>
                    </v-sheet>
                </v-fade-transition>
                <v-fade-transition>
                    <v-sheet v-show="!loading && !items.length" height="50vh">
                        <v-layout fill-height column justify-center align-center>
                            <v-card-title> 데이터가 없습니다</v-card-title>
                        </v-layout>
                    </v-sheet>
                </v-fade-transition>
            </v-sheet>

            <div class="pagination-wrap">
                <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
            </div>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
import { btn_primary, btn_secondary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import SubVisualPrimary from "@/components/client/sub/sub-visual-primary.vue";
import VisualBreadcrumbs from '@/components/client/inc/visual-breadcrumbs.vue';
import PageSection from "@/components/client/templates/page-section.vue";
import BrandListNav from "@/components/client/shop/brands/brand-list-nav.vue";
import BrandSearchBar from "@/components/client/shop/brands/brand-search-bar.vue";

export default {
    components: {
        ClientPage,
        SubVisualPrimary,
        VisualBreadcrumbs,
        PageSection,
        BrandListNav,
        BrandSearchBar
    },
    data: () => ({
        btn_primary,
        btn_secondary,

        brands: [],

        limit: 24,
        summary: { totalCount: 0 },
        loading: false,
    }),
    computed: {
        ...mapState(["categories"]),
        items() {
            return [...this.brands].map((item, index) => ({ index: this.summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            delete query.page;

            query.shows = true;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.brands = [];
            this.summary.totalCount = 0;
            this.loading = false;

            this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, brands } = await api.v1.shop.brands.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.brands = brands;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
